import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import {
  addUpload,
  addUploadGlob,
  commitUpload,
  addDocument
} from '../../../../redux/actions/uploadActions'

import { DropdownItem } from 'shards-react'

import { connect } from 'react-redux'



const UploadNotification = (props) => {

  const {uploadJob} = props

  useEffect(() => {
    if(uploadJob.STEP === "add-document") {
      props.addDocument(uploadJob)
    }else if(uploadJob.STEP === "add-upload") {
        props.createUpload(uploadJob)
    }else if(uploadJob.STEP === "add-glob") {
      let reader = new FileReader()
      let globSize = 0
      reader.onloadend = () => {
        if(reader.readyState == reader.DONE) {
          uploadJob.GLB = {
            D: Buffer.from(new Uint8Array(reader.result)).toString('hex'),
            GBSZ: globSize.toString()
          }
          props.addGlob(uploadJob)
        }
      }
      globSize = uploadJob.FILE.slice(uploadJob.BIDX,uploadJob.EIDX).size
      reader.readAsArrayBuffer(uploadJob.FILE.slice(uploadJob.BIDX,uploadJob.EIDX))
      //IF MORE BYTES LEFT TO READ
      if(uploadJob.EIDX <= uploadJob.SZ) {
        uploadJob.BIDX = uploadJob.EIDX
        uploadJob.EIDX = ((uploadJob.EIDX + uploadJob.GBSZ) > uploadJob.SZ ? uploadJob.SZ++ : uploadJob.EIDX + uploadJob.GBSZ)
      }
    }else if(uploadJob.STEP === "commit-upload") {
      props.commit(uploadJob)
    }
  })

  
  
  

  return (
    <DropdownItem>
      <div className="notification__icon-wrapper">
        <div className="notification__icon">
          <i className="material-icons">cloud_upload</i>
        </div>
      </div>
      <div className="w-100 notification__content">
        <span className="notification__category">Uploads</span>
        <p>{uploadJob.FLNM}</p>
        {!uploadJob.CMPD 
        ?
        <div className="mb-3 progress">
          <div className="progress-bar bg-primary"
            style={{width:uploadJob.PCT+'%'}}
            role="progressbar"
          ></div>
        </div>
        :
        <p>
          Upload Complete!
        </p>
        }
      </div>
    </DropdownItem>
  )
}

UploadNotification.propTypes = {
  uploadJob: PropTypes.object,
  createUpload: PropTypes.func,
  addGlob: PropTypes.func,
  commit: PropTypes.func
}

UploadNotification.defaultProps = {
  uploadJob: {
    FLNM: 'filename',
    SZ: 0,
    GBS: 0,
    GBSZ: 0,
    ENCD: false,
    CMPD: false,
    PCT: 0,
    ID: 0,
    FILE: null,
    STEP: "",
    IDX: 0,
    GLB: {},
    DOC: {}
  }
}

const mapDispatchToProps = dispatch => ({
  createUpload: (JOB) => dispatch(addUpload(JOB)),
  addGlob: (JOB) => dispatch(addUploadGlob(JOB)),
  commit: (JOB) => dispatch(commitUpload(JOB)),
  addDocument: (JOB) => dispatch(addDocument(JOB))
})

const mapStateToProps = state => ({
  loading: state.upload.loading
})

export default connect(mapStateToProps,mapDispatchToProps)(UploadNotification) 