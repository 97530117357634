/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	CardFooter,
	Form,
	FormGroup,
	FormInput,
	FormCheckbox,
	Button
} from "shards-react";

import {
	login, editLoginForm
} from '../redux/actions/authActions'


class Login extends React.Component {

	constructor(props) {
		super(props)

		this.renderLogin = this.renderLogin.bind(this)
	}

	render() {
		if(this.props.authenticated === true) {
			return (
				<Redirect to="/file-list" />
			)
		} else {
			return this.renderLogin()
		}
	}

	renderLogin() {
		const {error,login,editForm} = this.props
		return (
			<Container onKeyPress={(e) => {
				if(e.charCode === 13) 
					login(this.props.eml,this.props.pass)
			}} 
			fluid className="main-content-container h-100 px-4">
				<Row noGutters className="h-100">
					<Col lg="3" md="5" className="auth-form mx-auto my-auto">
						<Card>
							<CardBody>
								{/* Logo */}
								<img
									className="auth-form__logo d-table mx-auto mb-3"
									src={require("../images/SDX_LOGO.png")}
									alt="Shards Dashboards - Login Template"
									style={{maxWidth: '7vw', maxHeight: '7vh'}}
								/>

								{/* Title */}
								<h5 className="auth-form__title text-center mb-4">
									Access Your Account
            					</h5>

								{/* Form Fields */}
								<Form>

									{error !== null ? error.TP === 'INV_LOGIN'
									?
									<FormGroup>
										<b><p className="text-center"
										style={{color: 'red'}}>Incorrect Email or Password</p></b>
									</FormGroup>
									:
									''
									:
									''
									}
									<FormGroup>
										<label htmlFor="exampleInputEmail1">Email address</label>
										<FormInput
											type="email"
											id="exampleInputEmail1"
											placeholder="Enter email"
											autoComplete="email"
											defaultValue={this.props.eml}
											onChange={(e) => editForm(e.target.value, this.props.pass)}
											
										/>
									</FormGroup>
									<FormGroup>
										<label htmlFor="exampleInputPassword1">Password</label>
										<FormInput
											type="password"
											id="exampleInputPassword1"
											placeholder="Password"
											autoComplete="current-password"
											defaultValue={this.props.pass}
											onChange={(e) => editForm(this.props.eml,e.target.value)}
											
										/>
									</FormGroup>
									{/* <FormGroup>
										<FormCheckbox>Remember me for 30 days.</FormCheckbox>
									</FormGroup> */}
									<Button
										pill
										theme="accent"
										className="d-table mx-auto"
										// type="submit"
										onClick={(e) => {
											if(this.props.eml && this.props.pass) {
												login(this.props.eml, this.props.pass)
											}else {
												console.log('not ready to login. eml and pass: ', this.props.eml, ' ', this.props.pass)
											}
										}}
									>
										Login</Button>
								</Form>
							</CardBody>

							{/* Social Icons */}
							<CardFooter>
								<ul className="auth-form__social-icons d-table mx-auto">
									{/* <li>
										<a href="#">
											<i className="fab fa-facebook-f" />
										</a>
									</li>
									<li>
										<a href="#">
											<i className="fab fa-twitter" />
										</a>
									</li> */}
									{/* <li>
										<a href="#">
											<i className="fab fa-github" />
										</a>
									</li>
									<li>
										<a href="#">
											<i className="fab fa-google-plus-g" />
										</a>
									</li> */}
								</ul>
							</CardFooter>
						</Card>

						{/* Meta Details */}
						<div className="auth-form__meta d-flex mt-4 justify-content-center">
							<Link to="/forgot-password">Forgot your password?</Link>
							{/* <Link to="/register" className="ml-auto">
								Create a new account?
          					</Link> */}
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}


const mapStateToProps = state => ({
	error: state.auth.error,
	loading: state.auth.loading,
	eml: state.auth.AUTH.eml,
	pass: state.auth.AUTH.pass,
	USR: state.auth.USR,
	authenticated: state.auth.authenticated
})

const mapDispatchToProps = dispatch => ({
	login: (eml,pass) => dispatch(login(eml,pass)),
	editForm: (eml,pass) => dispatch(editLoginForm(eml,pass))
})

export default connect(mapStateToProps,mapDispatchToProps)(Login);
